<template>
  <v-bottom-navigation app v-model="value" color="primary" grow height="none">
    <v-btn :to="{ name: 'payments' }" :value="1" text color="primary">
      <span>Payment</span>
      <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
    </v-btn>
    <v-btn :to="{ name: 'customers' }" :value="2" text>
      <span>Network</span>
      <v-icon>{{ icons.mdiAccountSupervisorCircleOutline }}</v-icon>
    </v-btn>
    <v-btn :to="{ name: 'profile' }" :value="3" text color="primary">
      <span>Profile</span>
      <v-icon>{{ icons.mdiAccount }}</v-icon>
    </v-btn>
    <!-- <v-btn disabled :value="4">
      <span>Shop</span>

      <v-icon>{{ icons.mdiStore }}</v-icon>
    </v-btn>

    <v-btn disabled :value="5">
      <span>Cart</span>

      <v-icon>{{ icons.mdiCartOutline }}</v-icon>
    </v-btn> -->
  </v-bottom-navigation>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiStore, mdiAccount, mdiCartOutline, mdiAccountSupervisorCircleOutline, mdiCreditCardOutline } from '@mdi/js'
import NavMenuSectionTitle from './vertical-nav-menu/components/NavMenuSectionTitle.vue'
import NavMenuGroup from './vertical-nav-menu/components/NavMenuGroup.vue'
import NavMenuLink from './vertical-nav-menu/components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiCreditCardOutline,
        mdiCartOutline,
        mdiStore,
        mdiAccount,
        mdiAccountSupervisorCircleOutline,
      },
      value: 1,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
