import Vue from 'vue'
import { EventBus } from '@/utils/eventBus.js'

export default {
  data() {
    return {
      MultipleFileUploadLimit: 50,
      baseUrl: process.env.BASE_URL,
      datatableOptions: {
        mobileBreakpoint: 0,
        dense: true,
        class: 'table-rounded mt-2',
        loadingText: 'Loading... Please wait',
      },
      cardClass:'custom-slim-card'
    }
  },
  methods: {
    checkIfEmpty(val) {
      return val == null || val == '' || val == undefined || val == 0
    },
    GenerateRandomKey(length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    generateErrorMessage: function(validationErrors) {
      var jsonData = validationErrors
      var keys = Object.keys(jsonData)
      var errorMessages = jsonData[keys[0]]
      var messageString = ''
      for (var i = 0; i < errorMessages.length; i++) {
        messageString += '' + errorMessages[i] + '\n'
      }
      return messageString
    },
    showNotification(
      type = 'info',
      title = 'This is Notify Title',
      message = 'This is Notify Message,<br>with html.',
      duration = 6000,
      permanent = false,
    ) {
      this.$toast.info('Info toast')
    },
    successMessage(message) {
      this.$toast.success(message)
    },
    errorMessage(message, title = 'Error') {
      this.$toast.error(message)
    },
    infoMessage(message) {
      this.$toast.info(message)
    },
    warningMessage(message) {
      this.$toast.warning(message)
    },
    hasComponentPermission(componentCode) {
      let user = this.$store.getters.currentUser
      return user.accessibleComponentCodes.includes(componentCode)
    },
    isPasswordExpired() {
      let user = this.$store.getters.currentUser
      return user.isPasswordExpired
    },
    unflattenArray(array, parent, tree) {
      var e = this

      tree = typeof tree !== 'undefined' ? tree : []
      parent = typeof parent !== 'undefined' ? parent : { key: null }

      var children = array.filter(function(child) {
        return child.parentKey === parent.key
      })

      if (children.length) {
        if (!parent.key) {
          tree = children
        } else {
          parent['children'] = children
        }
        children.forEach(function(child) {
          e.unflattenArray(array, child)
        })
      }

      return tree
    },
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML
      var originalContents = document.body.innerHTML

      document.body.innerHTML = printContents

      window.print()

      document.body.innerHTML = originalContents
    },
  },
}
