export const defaultLocale = 'en'
export const defaultDirection = 'ltr'

export const apiUrl = 'https://localhost:44318/api'

export let currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: 'https://via.placeholder.com/150',
  date: 'Last seen today 15:24',
  token: '',
  isPasswordExpired: false,
}

export const isAuthActive = true
export const google_client_id = '195358588158-jhmvr6762h5gk8de7acdre9ken5i78em.apps.googleusercontent.com'

export class Roles {
  constructor() {
    this.Admin = 1
    this.Sponsor = 2
    this.QALead = 3
    this.Auditor = 4
    this.PeerReviewer = 5
  }
}

export let logoutAfterMins = 30

export const config = {
  dateValueFormat: 'YYYY-MM-DD',
  dateFormat: 'DD MMM YYYY',
  dateTimeFormat: 'DD MMM YYYY HH:mm:ss',
}
