import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRequired from '@/utils/AuthRequired'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/pages/login',
  },
  {
    path: '/c/reg/:i',
    name: 'registerCustomer',
    component: () => import('@/views/pages/RegisterCustomer.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/home/dashboard',
    beforeEnter: AuthRequired,
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/customers',
    name: 'customers',
    beforeEnter: AuthRequired,
    component: () => import('@/views/customer/Customers.vue'),
  },
  {
    path: '/payments',
    name: 'payments',
    beforeEnter: AuthRequired,
    component: () => import('@/views/payment/Payments.vue'),
  },
  {
    path: '/mandates/:customerID?',
    name: 'mandates',
    beforeEnter: AuthRequired,
    component: () => import('@/views/mandates/Mandates.vue'),
  },
  {
    path: '/transactions',
    name: 'transactions',
    beforeEnter: AuthRequired,
    component: () => import('@/views/transaction/Transactions.vue'),
  },
  {
    path: '/customer-vendor',
    name: 'customer-vendor',
    beforeEnter: AuthRequired,
    component: () => import('@/views/customerVendorMapping/Datatable.vue'),
  },
  {
    path: '/user/account-settings',
    name: 'pages-account-settings',
    beforeEnter: AuthRequired,
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/user/profile',
    name: 'profile',
    beforeEnter: AuthRequired,
    component: () => import('@/views/UserProfile.vue'),
  },
  {
    path: '/pages/login',
    name: 'login',
    component: () => import('@/views/pages/LoginLayout.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error/:errorCode/:message',
    name: 'error',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
