import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import createPersistedState from 'vuex-persistedstate'

const persistedStateOptions = {
  paths: ['user.currentUser'],
}

Vue.use(Vuex)
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
  },
  plugins: [createPersistedState(persistedStateOptions)],
})
