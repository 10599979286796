import { isAuthActive } from '../constants/config'
import store from '@/store' // your vuex store
export default (to, from, next) => {
  if (store.state.user.currentUser && isAuthActive) {
    if (store.state.user.currentUser.isPasswordExpired && to.name != 'pages-account-settings') {
      next('/user/account-settings')
    }
    next()
  } else {
    next('/pages/login')
  }
}
