import Axios from 'axios'
import { EventBus } from '@/utils/eventBus.js'

EventBus.$on('show-message', function(error) {
  this.$toast.error(error)
})

let productionUrl = ''
if (window.location.hostname == 'app.tenweeks.in') {
  productionUrl = 'https://api.app.tenweeks.in/api'
}
if (window.location.hostname == 'test.app.tenweeks.in') {
  productionUrl = 'https://test.api.app.tenweeks.in/api'
}
if (window.location.hostname == 'localhost') {
  productionUrl = 'https://localhost:44356/api'
}

Axios.defaults.baseURL = productionUrl

// Interceptor on request of any axios request
Axios.interceptors.request.use(
  function(config) {
    EventBus.$emit('updateTimer')
    // Do something before request is sent
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Interceptor on completion of any axios request
// Redirect to error page if error found while calling api
Axios.interceptors.response.use(null, function(error) {
  var message
  if (error.response.status === 400 || error.response.status === 404) {
    if (error.response.data.errors && error.response.data.errors.length) {
      message = generateErrorMessage(error.response.data.errors)
      let hasAttempts = message.search('attepts')
      if (hasAttempts > 0) message = message.replace('attepts', 'attempt[s]')
    }
    // else {
    //   message = "Something went wrong! Please contact administrator.";
    // }
    if (message) EventBus.$emit('show-message', message)
    return Promise.reject(error)
  } else if (error.response.status === 500) {
    //var message = error.response.data.message;
    EventBus.$emit('show-message', 'Something went wrong, Please try again later.')
    return Promise.reject(error)
  } else if (error.response.status === 401 || error.response.status === 403) {
    let link = '/error/' + error.response.status + '/'

    link += error.response.status == 401 ? 'Unauthorized' : 'Forbidden'

    //window.location.href = link
    return Promise.reject(error)
  } else {
    return Promise.reject(error)
  }

  function generateErrorMessage(validationErrors) {
    // var jsonData = validationErrors;
    // var keys = Object.keys(jsonData);
    var errorMessages = validationErrors
    var messageString = ''
    for (var i = 0; i < errorMessages.length; i++) {
      //messageString += '<p>' + errorMessages[i].title + '</p>'
      messageString += errorMessages[i].title + '\n'
    }
    return messageString
  }
})

export default {
  Axios: Axios,
}
